import { Button, Modal } from "react-bootstrap"
import { LoaderButton } from "../../../components/LoaderButton"
import { useState } from "react"
import { Api } from "../../../service/Api"
import { useToast } from "../../../contexts/ToastContext"

export const PlanCancelModal = ({ show, hide }) => {

    const toast = useToast()
    const [state, setState] = useState({ loading: false })

    const handleOnHide = () => {
        if (!state.loading) {
            hide()
        }
    }

    const handleConfirmCancel = () => {
        setState({ loading: true })

        const onCancelSuccess = () => {
            hide()
            toast.success('Sua assinatura foi cancelada!')
        }

        Api.cancelSubscription()
            .then(_ => onCancelSuccess())
            .catch(_ => toast.error('Desculpe, ocorreu um erro, tente novamente!'))
            .finally(_ => setState({ loading: false }))
    }

    return (
        <Modal show={show} onHide={handleOnHide}>
            <Modal.Header closeButton>
                <h6 className="m-0">Cancelar assinatura</h6>
            </Modal.Header>
            <Modal.Body className="py-4">
                <h6 className="m-0 mb-2">Deseja realmente cancelar sua assinatura?</h6>
                <small className="text-secondary">Você perderá os beneficios e voltará para versão <span className="text-danger fw-bold">FREE</span></small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleOnHide}>
                    Manter assinatura
                </Button>
                <LoaderButton className="btn btn-danger" onClick={handleConfirmCancel} loading={state.loading} style={{ minWidth: 100 }}>
                    Confirmar
                </LoaderButton>
            </Modal.Footer>
        </Modal>
    )
}