import { useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { getAuth, signOut } from "firebase/auth"
import { useNavigate } from "react-router-dom"

export const Logout = () => {

    const { setUser } = useAuth()
    const navigate = useNavigate()

    useEffect(_ => {
        navigate('/')
        setUser(null)
        signOut(getAuth())
    }, [])

    return <></>
}