import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { auth } from "../firebase";
import { Api, ensureUserDocument } from "../service/Api";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [firebaseUser, setFirebaseUser] = useState(null)

  const userUnsubscriberRef = useRef(null)

  useEffect(_ => {

    if (firebaseUser) {
      ensureUserDocument(firebaseUser).then(data => observeUser(data))
    } else {
      removeObserveUser()
      setLoading(false)
    }

    return () => removeObserveUser()
  }, [firebaseUser])

  /**
   * Clear user after logout
   */
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      let logged = firebaseUser !== undefined && firebaseUser !== null
      localStorage.setItem('logged', logged)
      setFirebaseUser(firebaseUser)
    })

    // Cleanup when the component unmounts
    return () => unsubscribe();

  }, []);

  const observeUser = (firebaseUser) => {
    if (userUnsubscriberRef.current) {
      userUnsubscriberRef.current()
    }

    const unsubscriber = Api.observeUser(firebaseUser.uid, user => {
      setUser(user)
      setLoading(false)
    })

    userUnsubscriberRef.current = unsubscriber
  }

  const removeObserveUser = () => {
    if (userUnsubscriberRef.current) {
      userUnsubscriberRef.current()
    }
  }

  return (
    <AuthContext.Provider value={{ loading, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext)
  const { loading, user, setUser } = context
  return { loading, user, setUser }
}