import { Container } from "react-bootstrap"
import { usePlans } from "../../../contexts/PlansContext"
import { Check } from "@mui/icons-material"

export const Plans = () => {

    const { plans } = usePlans()

    return (
        <section className="py-5 bg-white">
            <Container>
                <h4 className="text-center mb-5">Nossos planos</h4>

                <div className='Plan-container'>
                    {plans.map(plan => (
                        <Plan
                            key={plan.id}
                            plan={plan} />
                    ))}
                </div>
            </Container>
        </section>
    )
}

const Plan = ({ plan }) => {

    const formatMoney = (value) => {
        return parseFloat(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        })
    }

    return (
        <div className={`Plan ${plan.type.toLowerCase()} elevate-hover`}>
            <div>
                <h5 className='name'>{plan.name}</h5>
                <p className='price'>{formatMoney(plan.priceMonthly)}<span className='monthly'>/mês</span></p>
                <ul className='benefits'>
                    {plan.benefits.map((benefit, idx) => (
                        <li key={plan.id.concat(idx)}>
                            <Check />
                            {benefit}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}