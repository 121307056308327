import { Card, Form } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { useState } from "react"
import { LoaderButton } from "../../../components/LoaderButton"
import { Api } from "../../../service/Api"
import { useToast } from "../../../contexts/ToastContext"
import { useNavigate } from "react-router-dom"

export const Preferences = () => {

    const toast = useToast()
    const navigate = useNavigate()

    const { user } = useAuth()
    const [paused, setPaused] = useState(user?.preferences?.notificationsPaused === true)
    const [loading, setLoading] = useState(false)

    const handleChangeActive = () => {
        setPaused(!paused)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        const preferences = { notificationsPaused: paused }
        const newUser = { ...user, preferences }
        Api.updateUser(user.uid, newUser)
            .then(_ => {
                navigate(-1)
                toast.success('Preferências atualizadas!')
            })
            .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
            .finally(_ => setLoading(false))
    }

    return (
        <Card>
            <h5>Preferências</h5>
            <Form className="mt-4" onSubmit={handleFormSubmit}>

                <div className="border p-3 rounded">
                    <h6 className="mb-2"><strong>Envio de notificações</strong></h6>
                    <Form.Check
                        onChange={handleChangeActive}
                        checked={!paused}
                        type="switch"
                        id="custom-switch"
                        label={`Notificações ${paused ? 'pausadas' : 'ativas'}`} />
                </div>

                <LoaderButton className="btn btn-primary mt-4" type="submit" style={{ width: 120 }} loading={loading}>
                    Salvar
                </LoaderButton>
            </Form>
        </Card>
    )
}