import { WarningRounded } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { useAuth } from "../../../../contexts/AuthContext"

export const PreferenceAlert = () => {

    const { user } = useAuth()
    const notificationsPaused = user?.preferences?.notificationsPaused

    if (!notificationsPaused) {
        return <></>
    }

    return (
        <div className="alert alert-danger d-flex align-items-center gap-3" role="alert">
            <WarningRounded />
            <div>
                <h6 className="mt-2 mb-1">As notificações do Telegram estão desativadas!</h6>
                <Link to="/dashboard/account/preferences" className="alert-link">Clique aqui para ativá-las</Link>
            </div>
        </div>

    )
}