import { Container } from 'react-bootstrap';
import './../../../components/pages/HowItWorks.css'

const IconBuild = (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.833496" width="56" height="56" rx="28" fill="#3C84E7" />
        <path d="M35.6835 37.975C35.5502 37.975 35.4252 37.9542 35.3085 37.9125C35.1918 37.8708 35.0835 37.8 34.9835 37.7L29.8835 32.6C29.7835 32.5 29.7127 32.3917 29.671 32.275C29.6293 32.1583 29.6085 32.0333 29.6085 31.9C29.6085 31.7667 29.6293 31.6417 29.671 31.525C29.7127 31.4083 29.7835 31.3 29.8835 31.2L32.0085 29.075C32.1085 28.975 32.2168 28.9042 32.3335 28.8625C32.4502 28.8208 32.5752 28.8 32.7085 28.8C32.8418 28.8 32.9668 28.8208 33.0835 28.8625C33.2002 28.9042 33.3085 28.975 33.4085 29.075L38.5085 34.175C38.6085 34.275 38.6793 34.3833 38.721 34.5C38.7627 34.6167 38.7835 34.7417 38.7835 34.875C38.7835 35.0083 38.7627 35.1333 38.721 35.25C38.6793 35.3667 38.6085 35.475 38.5085 35.575L36.3835 37.7C36.2835 37.8 36.1752 37.8708 36.0585 37.9125C35.9418 37.9542 35.8168 37.975 35.6835 37.975ZM35.6835 35.6L36.4085 34.875L32.7335 31.2L32.0085 31.925L35.6835 35.6ZM21.9585 38C21.8252 38 21.696 37.975 21.571 37.925C21.446 37.875 21.3335 37.8 21.2335 37.7L19.1335 35.6C19.0335 35.5 18.9585 35.3875 18.9085 35.2625C18.8585 35.1375 18.8335 35.0083 18.8335 34.875C18.8335 34.7417 18.8585 34.6167 18.9085 34.5C18.9585 34.3833 19.0335 34.275 19.1335 34.175L24.4335 28.875H26.5585L27.4085 28.025L23.2835 23.9H21.8585L18.8335 20.875L21.6585 18.05L24.6835 21.075V22.5L28.8085 26.625L31.7085 23.725L30.6335 22.65L32.0335 21.25H29.2085L28.5085 20.55L32.0585 17L32.7585 17.7V20.525L34.1585 19.125L37.7085 22.675C37.9918 22.9583 38.2085 23.2792 38.3585 23.6375C38.5085 23.9958 38.5835 24.375 38.5835 24.775C38.5835 25.175 38.5085 25.5583 38.3585 25.925C38.2085 26.2917 37.9918 26.6167 37.7085 26.9L35.5835 24.775L34.1835 26.175L33.1335 25.125L27.9585 30.3V32.4L22.6585 37.7C22.5585 37.8 22.4502 37.875 22.3335 37.925C22.2168 37.975 22.0918 38 21.9585 38ZM21.9585 35.6L26.2085 31.35V30.625H25.4835L21.2335 34.875L21.9585 35.6ZM21.9585 35.6L21.2335 34.875L21.6085 35.225L21.9585 35.6Z" fill="white" />
    </svg>
)

const IconMonitor = (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="56" height="56" rx="28" fill="#14AE5C" />
        <path d="M20.5 37V32C20.5 31.45 20.6958 30.9792 21.0875 30.5875C21.4792 30.1958 21.95 30 22.5 30H34.5C35.05 30 35.5208 30.1958 35.9125 30.5875C36.3042 30.9792 36.5 31.45 36.5 32V37H20.5ZM25.5 29C24.1167 29 22.9375 28.5125 21.9625 27.5375C20.9875 26.5625 20.5 25.3833 20.5 24C20.5 22.6167 20.9875 21.4375 21.9625 20.4625C22.9375 19.4875 24.1167 19 25.5 19H31.5C32.8833 19 34.0625 19.4875 35.0375 20.4625C36.0125 21.4375 36.5 22.6167 36.5 24C36.5 25.3833 36.0125 26.5625 35.0375 27.5375C34.0625 28.5125 32.8833 29 31.5 29H25.5ZM22.5 35H34.5V32H22.5V35ZM25.5 27H31.5C32.3333 27 33.0417 26.7083 33.625 26.125C34.2083 25.5417 34.5 24.8333 34.5 24C34.5 23.1667 34.2083 22.4583 33.625 21.875C33.0417 21.2917 32.3333 21 31.5 21H25.5C24.6667 21 23.9583 21.2917 23.375 21.875C22.7917 22.4583 22.5 23.1667 22.5 24C22.5 24.8333 22.7917 25.5417 23.375 26.125C23.9583 26.7083 24.6667 27 25.5 27ZM25.5 25C25.7833 25 26.0208 24.9042 26.2125 24.7125C26.4042 24.5208 26.5 24.2833 26.5 24C26.5 23.7167 26.4042 23.4792 26.2125 23.2875C26.0208 23.0958 25.7833 23 25.5 23C25.2167 23 24.9792 23.0958 24.7875 23.2875C24.5958 23.4792 24.5 23.7167 24.5 24C24.5 24.2833 24.5958 24.5208 24.7875 24.7125C24.9792 24.9042 25.2167 25 25.5 25ZM31.5 25C31.7833 25 32.0208 24.9042 32.2125 24.7125C32.4042 24.5208 32.5 24.2833 32.5 24C32.5 23.7167 32.4042 23.4792 32.2125 23.2875C32.0208 23.0958 31.7833 23 31.5 23C31.2167 23 30.9792 23.0958 30.7875 23.2875C30.5958 23.4792 30.5 23.7167 30.5 24C30.5 24.2833 30.5958 24.5208 30.7875 24.7125C30.9792 24.9042 31.2167 25 31.5 25Z" fill="white" />
    </svg>
)

const IconAlert = (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.166992" width="56" height="56" rx="28" fill="#FFBB00" />
        <path d="M18.167 26C18.167 24.3333 18.5378 22.8042 19.2795 21.4125C20.0212 20.0208 21.017 18.8667 22.267 17.95L23.442 19.55C22.442 20.2833 21.6462 21.2083 21.0545 22.325C20.4628 23.4417 20.167 24.6667 20.167 26H18.167ZM36.167 26C36.167 24.6667 35.8712 23.4417 35.2795 22.325C34.6878 21.2083 33.892 20.2833 32.892 19.55L34.067 17.95C35.317 18.8667 36.3128 20.0208 37.0545 21.4125C37.7962 22.8042 38.167 24.3333 38.167 26H36.167ZM20.167 35V33H22.167V26C22.167 24.6167 22.5837 23.3875 23.417 22.3125C24.2503 21.2375 25.3337 20.5333 26.667 20.2V19.5C26.667 19.0833 26.8128 18.7292 27.1045 18.4375C27.3962 18.1458 27.7503 18 28.167 18C28.5837 18 28.9378 18.1458 29.2295 18.4375C29.5212 18.7292 29.667 19.0833 29.667 19.5V20.2C31.0003 20.5333 32.0837 21.2375 32.917 22.3125C33.7503 23.3875 34.167 24.6167 34.167 26V33H36.167V35H20.167ZM28.167 38C27.617 38 27.1462 37.8042 26.7545 37.4125C26.3628 37.0208 26.167 36.55 26.167 36H30.167C30.167 36.55 29.9712 37.0208 29.5795 37.4125C29.1878 37.8042 28.717 38 28.167 38ZM24.167 33H32.167V26C32.167 24.9 31.7753 23.9583 30.992 23.175C30.2087 22.3917 29.267 22 28.167 22C27.067 22 26.1253 22.3917 25.342 23.175C24.5587 23.9583 24.167 24.9 24.167 26V33Z" fill="white" />
    </svg>
)

export const HowItWorks = () => {
    const steps = [
        {
            id: 1,
            icon: IconBuild,
            title: 'Crie Seus Padrões',
            description: 'Você cria e configura seus padrões conforme sua estratégia de apostas.',
        },
        {
            id: 2,
            icon: IconMonitor,
            title: 'Monitoramento Automático',
            description: 'Nós analisamos os últimos resultados das roletas em tempo real para detectar seus padrões configurados.',
        },
        {
            id: 3,
            icon: IconAlert,
            title: 'Alertas Instantâneos',
            description: 'Enviamos notificações diretamente no seu Telegram quando os padrões forem detectados.',
        }
    ];

    return (
        <section id="howitworks" className='bg-white'>
            <Container className='HowItWorks'>
                <h2 className='title'>Como Funciona?</h2>
                <div className='steps'>
                    {steps.map((step) => (
                        <div key={step.id} className='step'>
                            {step.icon}
                            <h3 className='stepTitle'>{step.title}</h3>
                            <p className='description'>{step.description}</p>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
}