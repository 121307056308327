import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Api } from "../../../service/Api";
import { State } from "../../../components/State";
import { Card, Spinner } from "react-bootstrap";
import { CheckCircle, Error, Pending } from "@mui/icons-material";

export const CheckoutConcluded = () => {

    const [state, setState] = useState({ loading: false, error: false, data: null })
    const [searchParams] = useSearchParams()
    const preApprovalId = searchParams.get('preapproval_id')

    useEffect(_ => {
        setState({ loading: true, error: false, data: {} })

        if (preApprovalId) {
            Api.fetchSubscription(preApprovalId)
                .then(result => setState({ loading: false, error: false, data: result.data }))
                .catch(_ => setState({ loading: false, error: true, data: null }))
        }

    }, [preApprovalId])

    return (
        <Card className="mb-4 p-5">

            <State visible={state.loading}>
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                    <span className="px-3">Carregando..</span>
                </div>
            </State>

            <State visible={state.error}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="text-center">
                        <div className="icon-error-feedback"><Error /></div>
                        <h4 className="mt-2">Desculpe, ocorreu um erro</h4>
                        <p className="text-secondary">Não foi possível carregar os detalhes da sua assinatura</p>
                    </div>
                </div>
            </State>

            <State visible={state.data}>
                <State visible={state.data && state.data.status === 'authorized'}>
                    <div className="text-center">
                        <div className="icon-success-feedback"><CheckCircle /></div>
                        <h5 className="mt-3">Sua assinatura foi ativada com sucesso</h5>
                        <p className="fw-light">Apartir de agora benefícios da <b>{state.data && state.data.reason}</b> foram ativados em sua conta</p>
                    </div>
                </State>

                <State visible={state.data && state.data.status === 'pending'}>
                    <div className="text-center">
                        <div className="icon-pending-feedback"><Pending /></div>
                        <h5 className="mt-3">Sua assinatura está pendente</h5>
                        <p className="fw-light">Após a confirmação do pagamento, os benefícios da <b>{state.data && state.data.reason}</b> seram ativados em sua conta</p>
                    </div>
                </State>

                <State visible={state.data && state.data.status === 'rejected'}>
                    <div className="text-center">
                        <div className="icon-error-feedback"><Error /></div>
                        <h5 className="mt-3">Não foi possível ativar sua assinatura</h5>
                        <p className="fw-light">O pagamento foi rejeitado ou cancelado, tente novamente mais tarde</p>
                    </div>
                </State>
            </State>

        </Card>
    )
}