import './Sidebar.css'
import { useAuth } from "../contexts/AuthContext"
import { CloseRounded } from '@mui/icons-material';
import { PlanVersionButton } from './PlanVersionButton';
import { usePlans } from '../contexts/PlansContext';
import { Link } from 'react-router-dom';

export const PlanButton = ({ plans, userPlan, onClick }) => {

    if (!plans || plans.length === 0) return <></>

    let plan = plans.find(plan => plan.type === userPlan.type)

    if (!plan) return <></>

    return <PlanVersionButton type={plan.type} onClick={onClick}>{plan.name}</PlanVersionButton>
}

export const MenuListItem = ({ selected, onClick, item }) => {
    return <li>
        <Link onClick={onClick} to={item.path} className={selected ? `selected` : ''}>
            {item.icon} {item.label}
        </Link>
    </li>
}

export const Sidebar = ({ menu, open, toggle, onClickPlan, onClickMenuItem, menuSelected }) => {

    const { user } = useAuth()
    const { plans } = usePlans()

    if (!open) {
        return <></>
    }

    return (
        <aside className="sidebar">

            <div className='close'>
                <button onClick={toggle}>
                    <CloseRounded />
                </button>
            </div>

            <div className="header">
                <div className='img-wrapper'>
                    <img
                        src={user.photoURL}
                        alt={user.name}
                        onError={e => {
                            e.target.onerror = null; // Prevenir loops
                            e.target.src = '/images/profile.jpg'
                        }} />
                </div>
                <div>
                    <h6>{user.name}</h6>
                    <span>{user.email}</span>
                </div>
                <PlanButton
                    plans={plans}
                    userPlan={user.plan}
                    onClick={onClickPlan} />
            </div>
            <nav className='menu'>
                <ul>
                    {menu.map(item => {
                        return (
                            <MenuListItem
                                key={item.path}
                                item={item}
                                selected={menuSelected === item.path}
                                onClick={onClickMenuItem}
                            />
                        )
                    })}
                </ul>
            </nav>
        </aside>
    )
}