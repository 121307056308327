import { LoaderButton } from "../../../components/LoaderButton"
import { useAuth } from "../../../contexts/AuthContext"
import { PlanConfig } from "./ActivePlanConfig"

export const ActivePlan = ({ onClickCancel }) => {

    const { user } = useAuth()

    const planConfig = PlanConfig.find(el => el.type === user.plan.type)

    if (!planConfig) return <></>

    return (
        <div className={`rounded bg-white p-4 ${planConfig.borderClass}`}>
            <div>
                <h6 className='d-flex align-items-center gap-2 mb-4 text-uppercase fw-bold'>
                    {planConfig.icon}
                    {planConfig.title}
                </h6>
                <p className='text-secondary'>{planConfig.subtitle}</p>
                <ul>
                    {planConfig.benefits.map((benefit, index) => {
                        return (
                            <li key={`plan-benefit-${index}`}><b>{benefit.name}</b> {benefit.description}</li>
                        )
                    })}
                </ul>
            </div>

            <div className='mt-4'>
                <LoaderButton className='mb-2 btn btn-outline-danger' onClick={onClickCancel}>Cancelar Assinatura</LoaderButton>
                <small className='d-block text-secondary'>Nota: Ao cancelar, você perderá o acesso aos benefícios do plano Premium no próximo ciclo de faturamento.</small>
            </div>
        </div>
    )
}