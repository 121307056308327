import { CloseRounded } from "@mui/icons-material"
import { State } from "../../../../components/State"
import { useAuth } from "../../../../contexts/AuthContext"
import { useDashboard } from "../../../../contexts/DashboardContext"
import { Api } from "../../../../service/Api"
import { useNavigate } from "react-router-dom"
import { Card } from "react-bootstrap"

const ActionsType = {
    telegramConnect: 'telegram-connect',
    createPattern: 'create-pattern',
}

const ConcludedIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1 13.8L8.95 11.65C8.76667 11.4667 8.53333 11.375 8.25 11.375C7.96667 11.375 7.73333 11.4667 7.55 11.65C7.36667 11.8333 7.275 12.0667 7.275 12.35C7.275 12.6333 7.36667 12.8667 7.55 13.05L10.4 15.9C10.6 16.1 10.8333 16.2 11.1 16.2C11.3667 16.2 11.6 16.1 11.8 15.9L17.45 10.25C17.6333 10.0667 17.725 9.83333 17.725 9.55C17.725 9.26667 17.6333 9.03333 17.45 8.85C17.2667 8.66667 17.0333 8.575 16.75 8.575C16.4667 8.575 16.2333 8.66667 16.05 8.85L11.1 13.8ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z" fill="#25C16E" />
    </svg>
)

const NonConcludedIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6167 2.2625 9.32083 2.7875 8.1125C3.3125 6.90417 4.02917 5.84583 4.9375 4.9375C5.84583 4.02917 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3833 2 14.6792 2.2625 15.8875 2.7875C17.0958 3.3125 18.1542 4.02917 19.0625 4.9375C19.9708 5.84583 20.6875 6.90417 21.2125 8.1125C21.7375 9.32083 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.9708 18.1542 19.0625 19.0625C18.1542 19.9708 17.0958 20.6875 15.8875 21.2125C14.6792 21.7375 13.3833 22 12 22ZM12 18C13.6667 18 15.0833 17.4167 16.25 16.25C17.4167 15.0833 18 13.6667 18 12C18 10.3333 17.4167 8.91667 16.25 7.75C15.0833 6.58333 13.6667 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18Z" fill="#CCCCCC" />
    </svg>
)

export const StaterSteps = () => {

    const { user } = useAuth()
    const { patterns } = useDashboard()
    const navigate = useNavigate()

    const steps = [
        {
            id: 'stepper-1',
            title: 'Conecte-se com o Telegram',
            description: 'Vincule sua conta ao Telegram para receber as notificações em tempo real.',
            action_label: 'Conectar',
            action_type: ActionsType.telegramConnect,
            concluded: user.chatId
        },
        {
            id: 'stepper-2',
            title: 'Crie seu primeiro padrão',
            description: 'Configure um ou mais padrões que deseja monitorar nas roletas',
            action_label: 'Criar padrão',
            action_type: ActionsType.createPattern,
            concluded: patterns.length > 0
        }
    ]

    const isAllStepsConcluded = steps.every(el => el.concluded)

    const handleActionButtonClick = (actionType) => {
        if (actionType === ActionsType.createPattern) {
            navigate('/dashboard/patterns/new')
        }

        if (actionType === ActionsType.telegramConnect) {
            navigate('/dashboard/telegram')
        }
    }

    const handleCloseButtonClick = () => {
        const updateUser = { ...user }
        updateUser.starterStepsClosed = true
        Api.updateUser(user.uid, updateUser)
    }

    if (user.starterStepsClosed) {
        return <></>
    }

    return (
        <Card className="mb-3">

            <div className="d-flex align-items-top">
                <div className="flex-fill py-2">
                    <h5 style={{ fontSize: 19 }} className="m-0">
                        <State visible={!isAllStepsConcluded}>Olá {user.name}, conclua as etapas abaixo para começar a receber os alertas</State>
                        <State visible={isAllStepsConcluded}>Parabéns você concluiu todas as etapas</State>
                    </h5>

                    <State visible={isAllStepsConcluded}>
                        <p className="m-0 mt-2 text-secondary">A partir de agora, te enviaremos notificações assim que detectarmos seu padrão em qualquer roleta.</p>
                    </State>
                </div>

                <State visible={isAllStepsConcluded}>
                    <div>
                        <button className="btn-icon" onClick={handleCloseButtonClick}>
                            <CloseRounded style={{ color: '#999' }} />
                        </button>
                    </div>
                </State>
            </div>

            <hr style={{ color: '#999' }} />

            <div className="mt-2 d-flex flex-column gap-4">
                {steps.map(step => (
                    <Step
                        key={step.id}
                        step={step}
                        handleActionButtonClick={handleActionButtonClick}
                    />
                ))}
            </div>

        </Card>
    )
}

export const Step = ({ step, handleActionButtonClick }) => {
    return (
        <div className="d-flex gap-3">

            <div style={{ width: 24, height: 24 }}>
                <State visible={step.concluded}>{ConcludedIcon}</State>
                <State visible={!step.concluded}>{NonConcludedIcon}</State>
            </div>

            <div>
                <h6 style={{ fontSize: 16 }} className="m-0 mb-1">{step.title}</h6>
                <p style={{ fontSize: 14 }} className="m-0 mb-1 text-secondary">
                    <State visible={!step.concluded}>{step.description}</State>
                    <State visible={step.concluded}>Você concluiu esta etapa</State>
                </p>

                <State visible={!step.concluded}>
                    <button className="btn-link" onClick={_ => handleActionButtonClick(step.action_type)}>
                        {step.action_label}
                    </button>
                </State>
            </div>
        </div>
    )
}