import { Container } from "react-bootstrap"

export const Telegram = ({ clickCreateAccount }) => {
    return (
        <section className="py-5">
            <Container>
                <div className="Home-Telegram">
                    <div className="text-center">
                        <h2 className="mb-3">Momento certo para jogar na roleta</h2>
                        <p className="mb-3">Nós monitoramos e te enviamos uma notificação assim que seu padrão estiver sendo respeitado, em tempo real.</p>
                        <button type="button" className="btn btn-primary" onClick={clickCreateAccount}>Faça um teste grátis</button>
                    </div>

                    <img src="/images/home-hero.webp" alt="Telegram in app message" />
                </div>
            </Container>
        </section>
    )
}