import { useState } from "react"
import { PatternFormValidator } from "./PatternFormValidator"
import { useDashboard } from "../../../../contexts/DashboardContext"

/**
 * Create a simple rule object to manipulate in the form
 * @returns 
 */
const createRule = (startFrom = '') => ({
    from: {
        value: startFrom,
        error: ''
    },
    to: {
        value: [],
        error: ''
    }
})

/**
 * 
 * Create the initial object of the form
 * 
 * @param {*} patterns 
 * @param {*} id 
 * @returns 
 */
const getInitialObject = (patterns, id) => {
    let name = ''
    let notifyAfter = 1
    let roundTolerance = 1
    let rules = [createRule(), createRule()]
    let active = true
    let isValid = false

    let editableObject = patterns.find(el => el.id === id)
    if (editableObject) {

        let adjustedRules = editableObject.rules.map(rule => {
            let from = rule.from
            let to = rule.to.map(string => string.split(',').map(el => parseInt(el)))
            return { from, to }
        })

        editableObject = {
            ...editableObject,
            rules: adjustedRules
        }

        isValid = true
        name = editableObject.name
        notifyAfter = editableObject.notifyAfter
        roundTolerance = editableObject.roundTolerance
        active = editableObject.active
        rules = editableObject.rules.map(rule => {
            return ({
                from: {
                    value: rule.from,
                    error: ''
                },
                to: {
                    value: rule.to,
                    error: ''
                }
            })
        })
    }

    const finalObject = {
        name: { value: name, error: '' },
        notifyAfter: { value: notifyAfter, error: '' },
        roundTolerance: { value: roundTolerance, error: '' },
        rules: rules,
        active: active,
        isValid: isValid
    }

    return finalObject
}

/**
 * FormHook Component
 * 
 * @param id user to edit pattern 
 * @returns FormHook
 */
export const usePatternForm = (id) => {

    const dashboard = useDashboard()

    const [form, setForm] = useState(getInitialObject(dashboard.patterns, id))

    const addRule = () => {
        setForm(form => {

            let newRule = null
            let hasRules = form.rules.length > 0
            let lastRuleValue = hasRules && form.rules[form.rules.length - 1].from.value
            let newRuleNumber = parseInt(lastRuleValue) + 1

            if (newRuleNumber <= 36) {
                newRule = createRule(newRuleNumber)
            } else {
                newRule = createRule()
            }

            let newForm = { ...form }
            newForm.rules = [...form.rules, newRule]
            _applyValidation(newForm)
            return newForm
        })
    }

    const deleteRule = (index) => {
        setForm(form => {
            let newForm = { ...form }
            newForm.rules = newForm.rules.filter((_, i) => i !== index)
            _applyValidation(newForm)
            return newForm
        })
    }

    const setName = (name) => {
        setForm(form => {
            let newForm = { ...form }
            newForm.name.value = name
            newForm.name.error = PatternFormValidator.validateName(name)
            _applyValidation(newForm)
            return newForm
        })
    }

    const setNumberFrom = (ruleIndex, number) => {
        setForm(form => {
            let newForm = { ...form }
            let rule = newForm.rules[ruleIndex]
            rule.from.value = number
            _applyValidation(newForm)
            return newForm
        })
    }

    const setNumbersTo = (ruleIndex, numbers) => {
        setForm(form => {
            let newForm = { ...form }
            let rule = newForm.rules[ruleIndex]
            rule.to.value = numbers
            _applyValidation(newForm)
            return newForm
        })
    }

    const setNotifyCount = (count) => {
        setForm(form => {
            let newForm = { ...form }
            newForm.notifyAfter.value = count
            _applyValidation(newForm)
            return newForm
        })
    }

    const setRoundTolerance = (count) => {
        setForm(form => {
            let newForm = { ...form }
            newForm.roundTolerance.value = count
            _applyValidation(newForm)
            return newForm
        })
    }

    const toggleActive = () => {
        setForm(form => {
            let newForm = { ...form }
            newForm.active = !newForm.active
            return newForm
        })
    }

    const validateForm = () => setForm(form => {
        let newForm = { ...form }
        _applyValidation(newForm)
        return newForm
    })

    const getAsObject = () => {
        return {
            id,
            name: form.name.value,
            rules: form.rules.map(rule => ({ from: parseInt(rule.from.value), to: rule.to.value.map(arr => arr.join(',')) })),
            notifyAfter: parseInt(form.notifyAfter.value),
            roundTolerance: parseInt(form.roundTolerance.value),
            active: form.active
        }
    }

    const _applyValidation = (newForm) => {
        function validateRule(rule, index, allRules) {
            let ruleValidatorResult = PatternFormValidator.validateRule(rule, index, allRules)
            rule.from.error = ruleValidatorResult.from
            rule.to.error = ruleValidatorResult.to
        }

        newForm.name.error = PatternFormValidator.validateName(form.name.value)
        newForm.rules.forEach((rule, index) => validateRule(rule, index, newForm.rules))
        newForm.isValid = _checkIsValid(newForm)
    }

    const _checkIsValid = (form) => (
        !form.name.error &&
        !form.notifyAfter.error &&
        form.rules.every(rule => !rule.from.error && !rule.to.error)
    )

    return {
        formData: form,
        addRule,
        deleteRule,
        setName,
        setNumbersTo,
        setNumberFrom,
        setNotifyCount,
        setRoundTolerance,
        toggleActive,
        validateForm,
        getAsObject
    }
}