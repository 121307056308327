import { Check, Info } from '@mui/icons-material'
import { LoaderButton } from '../../../components/LoaderButton'
import { useEffect, useState } from 'react'
import { Api } from '../../../service/Api'
import { useToast } from '../../../contexts/ToastContext'
import { useAuth } from '../../../contexts/AuthContext'
import { ActivePlan } from './ActivePlan'
import { PlanCancelModal } from './PlanCancelModal'
import { usePlans } from '../../../contexts/PlansContext'

export const Plans = () => {

    const toast = useToast()
    const { user } = useAuth()
    const { plans } = usePlans()
    const [localPlans, setPlans] = useState(plans)
    const [cancelModelOpen, setCancelModalOpen] = useState(false)

    useEffect(_ => {
        setPlans(plans)
    }, [plans])

    function setPlanLoading(plan, loading) {
        setPlans(state => {
            let newPlans = [...state]
            newPlans.find(el => el.id === plan.id).loading = loading
            return newPlans
        })
    }

    function handleClickAssignPlan(plan) {
        setPlanLoading(plan, true)
        Api.createSubscription(plan.id)
            .then(result => { window.location.href = result.data.init_point })
            .catch(_ => toast.error('Ocorreu um erro'))
            .finally(_ => setPlanLoading(plan, false))
    }

    function handleClickCancelPlan() {
        setCancelModalOpen(true)
    }

    if (user.plan.type !== 'FREE' && user.plan.status !== 'cancelled') {
        return (
            <>
                <ActivePlan onClickCancel={handleClickCancelPlan} />
                <PlanCancelModal
                    hide={_ => setCancelModalOpen(false)}
                    show={cancelModelOpen} />
            </>
        )
    }

    return (
        <>
            <CancelledPlanCard user={user} />
            <div className='Plan-container'>
                {localPlans.map(plan => (
                    <Plan
                        key={plan.id}
                        plan={plan}
                        onClickAssingPlan={handleClickAssignPlan} />
                ))}
            </div>
        </>
    )
}

const Plan = ({ plan, onClickAssingPlan }) => {

    const formatMoney = (value) => {
        return parseFloat(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        })
    }

    return (
        <div className={`Plan ${plan.type.toLowerCase()}`}>
            <div>
                <h5 className='name'>{plan.name}</h5>
                <p className='price'>{formatMoney(plan.priceMonthly)}<span className='monthly'>/mês</span></p>
                <ul className='benefits'>
                    {plan.benefits.map((benefit, idx) => (
                        <li key={plan.id.concat(idx)}>
                            <Check />
                            {benefit}
                        </li>
                    ))}
                </ul>
            </div>

            <LoaderButton
                loading={plan.loading}
                visible={plan.type !== "FREE"}
                onClick={_ => onClickAssingPlan(plan)}>
                Assinar {plan.name}
            </LoaderButton>
        </div>
    )
}

const CancelledPlanCard = ({ user }) => {

    if (user.plan.type !== 'FREE' && user.plan.status === 'cancelled') {
        return (
            <div className='card mb-3 d-flex align-items-center flex-row gap-3'>
                <Info color='info' />
                <div>
                    <h6 className='m-0 mt-2 fw-bold'>Assinatura cancelada</h6>
                    <p className='m-0'>Você cancelou sua assinatura, mas ainda pode aproveitar todos os benefícios até o dia {user.plan.expirationDate.toDate().toLocaleString()}.</p>
                </div>
            </div>
        )
    }

    return <></>
}