import { ErrorOutlineRounded } from "@mui/icons-material"
import { Card } from "react-bootstrap"
import { LinkButton } from "../../../components/LinkButton"

export const PatternLimitReached = () => {
    return (
        <Card className="p-4 text-center">
            <span className="mb-3 text-warning"><ErrorOutlineRounded style={{ fontSize: 32 }} /></span>
            <h5>Você atingiu o limite de padrões permitidos para seu plano atual</h5>
            <p className="mb-0">Considere fazer um upgrade de plano para criar novos padrões</p>

            <LinkButton className="mt-4 w-100 mx-auto" href="/dashboard/plans" style={{ maxWidth: 400 }}>Acessar planos</LinkButton>
        </Card>
    )
}