import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Api } from "../service/Api";
import { useAuth } from "./AuthContext";

const DashboardContext = createContext();

const defaultDataObject = {
    data: [],
    loading: false,
    error: false
}

export const DashboardProvider = ({ children }) => {

    const { user } = useAuth()

    const [patterns, setPatterns] = useState([])
    const [alerts, setAlerts] = useState([])
    const [roulettes, setRoulettes] = useState(defaultDataObject)

    const alertsUnsubscriberRef = useRef(null)
    const patternsUnsubscriberRef = useRef(null)

    useEffect(_ => {
        if (user) {
            observeAlerts()
            observePatterns()
        } else {
            clearStorage()
        }
    }, [user])

    useEffect(_ => {
        fetchRoulettes()
    }, [])

    const clearStorage = () => {
        if (alertsUnsubscriberRef.current) {
            alertsUnsubscriberRef.current()
        }

        if (patternsUnsubscriberRef.current) {
            patternsUnsubscriberRef.current()
        }
    }

    const fetchRoulettes = () => {
        setRoulettes({ data: [], loading: true, error: false })
        Api.fetchRoulettes()
            .then(data => setRoulettes(state => ({ ...state, data })))
            .catch(_ => setRoulettes(state => ({ ...state, error: true })))
            .finally(_ => setRoulettes(state => ({ ...state, loading: false })))
    }


    const observePatterns = () => {
        if (patternsUnsubscriberRef.current) {
            patternsUnsubscriberRef.current()
        }

        const unsubscriber = Api.observePatterns(user.uid, patterns => setPatterns(patterns))
        patternsUnsubscriberRef.current = unsubscriber
    }

    const observeAlerts = () => {
        if (alertsUnsubscriberRef.current) {
            alertsUnsubscriberRef.current()
        }

        const unsubscriber = Api.observeAlerts(user.uid, alerts => setAlerts(alerts))
        alertsUnsubscriberRef.current = unsubscriber
    }

    const storage = {
        patterns,
        roulettes,
        alerts
    }

    return (
        <DashboardContext.Provider value={{ storage }}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => {

    const { storage } = useContext(DashboardContext)

    return {
        patterns: storage.patterns,
        roulettes: storage.roulettes,
        alerts: storage.alerts
    }
}