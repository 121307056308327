import './PatternsSection.css'
import { EmptyData } from '../../../../components/EmptyData'
import { useDashboard } from '../../../../contexts/DashboardContext'
import { StatusDot } from '../../../../components/StatusDot'
import { Card } from 'react-bootstrap'

const PatternView = ({ pattern }) => {
    return (
        <div className='pattern-view'>
            <h6 className='m-0 mb-2'>{pattern.name}</h6>
            <StatusDot
                variant={pattern.active ? 'success' : 'danger'}
                label={pattern.active ? 'Ativo' : 'Inativo'} />
        </div>
    )
}

export const PatternsSection = ({ onClickCreatePattern }) => {

    const dashboard = useDashboard()

    const hasPatterns = dashboard.patterns.length > 0

    if (hasPatterns) {
        return (
            <Card className='mb-3'>
                <h5 className="mb-3">Padrões</h5>

                <div className="pattern-list">
                    {dashboard.patterns.map((pattern, idx) =>
                        <PatternView key={idx} pattern={pattern} />)}
                </div>
            </Card>
        )
    }

    return (
        <Card className='mb-3'>
            {(!hasPatterns) && (
                <EmptyData
                    title="Nenhum padrão criado"
                    subtitle="Crie seu primeiro padrão para começar a receber alertas"
                    button="Criar meu primeiro padrão"
                    buttonClick={onClickCreatePattern} />
            )}
        </Card>
    )
}