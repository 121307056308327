import './Dashboard.css'

import { useCallback, useEffect, useState } from "react"
import { DashboardHeader } from "../../components/DashboardHeader"
import { Sidebar } from "../../components/Sidebar"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Breadcrumb } from "./Breadcrumb"
import { AccountCircleRounded, HomeRounded, PatternRounded, Telegram, VerifiedRounded } from "@mui/icons-material"
import { Container } from "react-bootstrap"

const LARGE_DEVICE_SIZE = 992

const menu = [
    {
        label: 'Início',
        icon: <HomeRounded />,
        path: '/dashboard/home',
        breadcrumb: '',
        children: []
    },
    {
        label: 'Meus Padrões',
        icon: <PatternRounded />,
        path: '/dashboard/patterns',
        breadcrumb: 'patterns',
        children: [
            {
                label: 'Criar Padrão',
                path: '/new',
                breadcrumb: 'patterns/new'
            },
            {
                label: 'Editar',
                path: '/edit',
                breadcrumb: 'patterns/edit',
            }
        ]
    },
    {
        label: 'Telegram',
        icon: <Telegram />,
        path: '/dashboard/telegram',
        breadcrumb: 'telegram',
        children: []
    },
    {
        label: 'Planos',
        icon: <VerifiedRounded />,
        path: '/dashboard/plans',
        breadcrumb: 'plans',
        children: []
    },
    {
        label: 'Minha Conta',
        icon: <AccountCircleRounded />,
        path: '/dashboard/account',
        breadcrumb: 'account',
        children: [
            {
                label: 'Preferências',
                path: '/preferences',
                breadcrumb: 'account/preferences',
            }
        ]
    }
]

export const Dashboard = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const checkIsLargeDevice = () => (typeof window !== 'undefined' ? window.innerWidth > LARGE_DEVICE_SIZE : false)
    const [isLargeDevice, setIsLargeDevice] = useState(checkIsLargeDevice())

    const [sidebarOpen, setSidebarOpen] = useState(checkIsLargeDevice())
    const toggleSidebarOpen = () => setSidebarOpen(!sidebarOpen)

    const [menuSelected, setMenuSelected] = useState(location.pathname)

    const handleChangePath = useCallback(_ => {
        let path = location.pathname
        let menuItem = menu.find(menu => path.includes(menu.path))
        setMenuSelected(menuItem.path)
    }, [location.pathname])

    useEffect(handleChangePath, [handleChangePath])
    useEffect(() => {

        const handleResize = () => {
            let isDeviceLarge = checkIsLargeDevice()
            setIsLargeDevice(isDeviceLarge)
            setSidebarOpen(isDeviceLarge)
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function onClickMenuItem() {
        if (!isLargeDevice) {
            setSidebarOpen(false)
        }
    }

    function onClickSidebarPlan() {
        navigate('plans')
        if (!isLargeDevice) {
            setSidebarOpen(false)
        }
    }

    return (
        <div className="app-dashboard">

            <Sidebar
                menu={menu}
                menuSelected={menuSelected}
                open={sidebarOpen}
                onClickMenuItem={onClickMenuItem}
                onClickPlan={onClickSidebarPlan}
                toggle={toggleSidebarOpen} />

            <main className="main-content">

                <header className='top-header'>
                    <DashboardHeader toggle={toggleSidebarOpen} />
                </header>

                <section className='content'>
                    <Container>
                        <Breadcrumb menu={menu} />
                        <Outlet />
                    </Container>
                </section>

            </main>
        </div>
    )
}