import './../../components/pages/PublicHome.css'

import { useNavigate } from "react-router-dom"
import { Header } from "../../components/Header"
import { Hero } from "./sections/Hero"
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Faq } from "./sections/Faq";
import { Footer } from "./sections/Footer";
import { Testimonials } from "./sections/Testimonials";
import { Plans } from "./sections/Plans";
import { HowItWorks } from "./sections/HowItWorks";
import { Telegram } from "./sections/Telegram";

export const Home = () => {

    const { user } = useContext(AuthContext);
    const navigate = useNavigate()

    function onClickCreateAccount() {
        if (user) {
            navigate("/dashboard/home");
        } else {
            navigate("/login");
        }
    }

    return (
        <>
            <Header clickCreateAccount={onClickCreateAccount} />
            <Hero clickCreateAccount={onClickCreateAccount} />
            <HowItWorks />
            <Telegram clickCreateAccount={onClickCreateAccount} />
            <Plans />
            <Testimonials />
            <Faq />
            <Footer />
        </>
    )
}