import { useNavigate } from "react-router-dom"
import { AlertsSection } from "./components/AlertsSection"
import { PatternsSection } from "./components/PatternsSection"
import { StaterSteps } from "./components/StarterSteps"
import { PreferenceAlert } from "./components/PreferenceAlert"

export const DashboardHome = () => {

    const navigate = useNavigate()

    function goCreatePattern() {
        navigate('/dashboard/patterns/new')
    }

    return (
        <>
            <PreferenceAlert />
            <StaterSteps />
            <PatternsSection onClickCreatePattern={goCreatePattern} />
            <AlertsSection onClickCreatePattern={goCreatePattern} />
        </>
    )
}