import './Telegram.css'
import { useState } from 'react'
import { Button, Card, Form, Spinner } from "react-bootstrap"
import { State } from '../../../components/State'
import { useToast } from '../../../contexts/ToastContext'
import { saveTelegramCode } from '../../../service/Api'
import { useAuth } from '../../../contexts/AuthContext'

const BOT_LINK = "https://t.me/roulettesmarterbot"

export const Telegram = () => {

    const toast = useToast()
    const { user, setUser } = useAuth()
    const [form, setForm] = useState({
        value: (user.chatId || ''),
        loading: false,
        disabled: user.chatId,
        isValid: user.chatId || false,
        connected: user.chatId,
        error: ''
    })

    function handleChangeCode(event) {
        const regex = /\b\d{5,}\b/

        let value = event.target.value
        let isValid = value && value.length > 0 && value.match(regex)
        let error = null

        if (value && !value.match(regex)) {
            error = 'O código informado é inválido'
        }

        setForm(mForm => ({ ...mForm, value, isValid, error }))
    }

    function handleSubmitForm(event) {
        event.preventDefault()

        /**
         * First of all 
         * 
         * Allow edit if needed
         */
        if (form.connected) {
            return setForm(mForm => ({ ...mForm, connected: false, disabled: false }))
        }

        if (form.error) {
            return toast.error(form.error)
        }

        else if (!form.isValid) {
            let error = 'Informe o código corretamente para conectar com o Telegram'
            setForm(mForm => ({ ...mForm, error }))
            return toast.error(error)
        } else {
            const handleSuccess = (data) => {
                toast.success('Conectado com sucesso!')
                setForm(mForm => ({ ...mForm, disabled: true, connected: true }))
                setUser(user => ({ ...user, ...data }))
            }

            setForm(mForm => ({ ...mForm, loading: true }))
            saveTelegramCode(user.uid, form.value)
                .then(data => handleSuccess(data))
                .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
                .finally(_ => setForm(mForm => ({ ...mForm, loading: false })))
        }
    }

    return (
        <div className="Telegram">
            <Card>
                <h5>Telegram</h5>
                <p>Conecte-se com sua conta do Telegram para receber os alertas</p>

                <div className='steps'>
                    <p className="fw-bold">Você precisar realizar as etapas abaixo para conectar com Telegram</p>
                    <p>1. Acesse nosso bot do Telegram. <a href={BOT_LINK} target="_blank" rel="noopener noreferrer">[Clique aqui para acessar o bot]</a></p>
                    <p>2. Toque em <span>Iniciar </span> ou envie uma mensagem como seguinte texto <code>/start</code> </p>
                    <p>3. Digite o código informado pelo bot no campo abaixo</p>
                </div>

                <Form onSubmit={handleSubmitForm} >
                    <div className='form-group'>

                        <Form.Control
                            isValid={form.isValid && !form.error}
                            isInvalid={form.error}
                            value={form.value}
                            disabled={form.disabled || form.loading}
                            onChange={handleChangeCode}
                            type="text"
                            placeholder='Digite o código aqui' />

                        <Button variant={form.connected ? 'outline-dark' : 'primary'} disabled={form.loading} className='h-100' style={{ width: 160 }} type='submit'>
                            <State visible={form.loading}><Spinner size='sm' /></State>
                            <State visible={!form.loading}>{form.connected ? 'Editar' : 'Conectar'}</State>
                        </Button>

                    </div>
                </Form>
            </Card>
        </div>
    )
}