import { createContext, useContext, useEffect, useState } from "react";
import { Api } from "../service/Api";

const PlansContext = createContext();

export const PlansProvider = ({ children }) => {

    const [plans, setPlans] = useState([])

    useEffect(_ => {
        fetchPlans()
    }, [])

    const fetchPlans = () => {
        Api.fetchPlans().then(data => setPlans(data))
    }

    return (
        <PlansContext.Provider value={{ plans }}>
            {children}
        </PlansContext.Provider>
    )
}

export const usePlans = () => {
    const { plans } = useContext(PlansContext)
    return { plans }
}