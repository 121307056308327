import './DashboardHeader.css'

import { MenuRounded } from "@mui/icons-material"
import { useAuth } from "../contexts/AuthContext"
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export const DashboardHeader = ({ toggle }) => {

    const { user } = useAuth()

    return (
        <div className="d-flex flex-fill align-items-center gap-2">
            <button onClick={toggle} className="btn btn-light bg-white">
                <MenuRounded />
            </button>

            <div className="flex-fill">
                <h1 className="m-0 h6 d-inline text-gradient">
                    <Link to={'/dashboard/home'}><strong>Roulette Notify</strong></Link>
                </h1>
            </div>

            <Dropdown drop='down'>
                <Dropdown.Toggle className='btn-account' variant='light'>
                    <div className='img-wrapper'>
                        <img
                            src={user.photoURL}
                            alt={user.name}
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = '/images/profile.jpg'
                            }} />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/dashboard/account">Minha Conta</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/logout">Sair</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}