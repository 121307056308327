import './OptionItem.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const DisabledOption = ({ children }) => {
    return (
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Em breve</Tooltip>}>
            <div className="option-item disabled">{children}</div>
        </OverlayTrigger>
    )
}

export const OptionItem = ({ children, disabled }) => {
    if (disabled) {
        return <DisabledOption>{children}</DisabledOption>
    } else {
        return <div className="option-item">{children}</div>
    }
}