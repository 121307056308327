import React from "react";
import { ChevronRight } from "@mui/icons-material";
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumb = ({ menu }) => {

    const location = useLocation();
    const breadCrumbList = menu.map(el => [el, ...el.children]).flat().filter(el => location.pathname.includes(el.breadcrumb))

    return (
        <BootstrapBreadcrumb>
            {breadCrumbList.map((item, index) => {
                const isLast = breadCrumbList.length === index + 1;
                return <BreadcrumbItem key={item.label} item={item} isLast={isLast} />
            })}
        </BootstrapBreadcrumb>
    )
}

const BreadcrumbItem = ({ item, isLast }) => {
    return (
        <BootstrapBreadcrumb.Item linkAs={Link} linkProps={{ to: item.path }} active={isLast}>
            {item.label}
            {!isLast && <ChevronRight color="disabled" />}
        </BootstrapBreadcrumb.Item>
    )
}
