function isNumberInRaceRange(str) {
    return /^(?:[0-9]|[1-2][0-9]|3[0-6])$/.test(str);
}

function containsOnlyNumbers(str) {
    return /^[0-9]*$/.test(str);
}

const validateName = (name) => {

    /**
     * Check has name
     */
    if (!name || name.length === 0 || name === '') {
        return 'O nome é obrigatório'
    }

    /**
     * Check has name min length
     */
    else if (name.length <= 3) {
        return 'O nome é muito curto'
    }

    /**
     * Check has name max length
     */
    else if (name.length >= 50) {
        return 'O nome é muito grande'
    }

    /**
     * Check name has letters
     */
    else if (containsOnlyNumbers(name)) {
        return 'O nome deve conter letras'
    }

    return ''
}

const validateRule = (rule, ruleIndex, allRules) => {
    let errorFrom = ''
    let errorTo = ''

    if (!isNumberInRaceRange(rule.from.value)) {
        errorFrom = 'Informe um número de 0 a 36'
    }

    else if (rule.from.value.length < 1 && rule.to.value.flat().length > 0) {
        errorFrom = 'Informe o gatilho para esta regra'
    }

    if (rule.to.value.flat().filter(n => !isNumberInRaceRange(n)).length > 0) {
        errorTo = 'Informe uma lista de números de 0 a 36'
    }

    else if (rule.from.value && isNumberInRaceRange(rule.from.value) && rule.to.value.flat().length <= 0) {
        errorTo = 'Informe os alvos para esta regra'
    }

    if (allRules.filter((_, index) => index !== ruleIndex).some(mRule => mRule.from.value === rule.from.value)) {
        errorFrom = 'Já existe uma regra para este número'
    }

    return { from: errorFrom, to: errorTo }
}

const validateForm = (name, rules) => {
    let errorName = validateName(name)
    let errorRules = rules.map(rule => validateRule(rule))
    let isValid = !errorName && errorRules.every(e => !e.from && !e.to)
    return isValid
}

export const PatternFormValidator = {
    validateName,
    validateRule,
    validateForm
}